// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-calculator-page-js": () => import("./../../../src/templates/calculator-page.js" /* webpackChunkName: "component---src-templates-calculator-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-pay-stub-page-js": () => import("./../../../src/templates/pay-stub-page.js" /* webpackChunkName: "component---src-templates-pay-stub-page-js" */),
  "component---src-templates-payroll-page-js": () => import("./../../../src/templates/payroll-page.js" /* webpackChunkName: "component---src-templates-payroll-page-js" */),
  "component---src-templates-showcase-page-js": () => import("./../../../src/templates/showcase-page.js" /* webpackChunkName: "component---src-templates-showcase-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-tax-table-page-js": () => import("./../../../src/templates/tax-table-page.js" /* webpackChunkName: "component---src-templates-tax-table-page-js" */),
  "component---src-templates-tools-page-js": () => import("./../../../src/templates/tools-page.js" /* webpackChunkName: "component---src-templates-tools-page-js" */)
}

